@import 'src/assets/styles/mixins';
.c-bkb-modal-view {
    z-index: 11111111111;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: 0.5s;
    pointer-events: none;
    overflow-y: auto;

    &--background {
        background: #0c204dd9;
        mix-blend-mode: normal;
        pointer-events: all;
    }

    &__close-btn-alert {
        position: absolute;
        top: 50%;
        cursor: pointer;
        right: 10px;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);
        display: none;
    }

    &__content {
        position: absolute;
        background: var(--c-white);
        mix-blend-mode: normal;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        gap: 36px;

        &--center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &--right {
            right: 3.75%;
        }

        &--button {
            bottom: 3.75%;
        }

        &--theme {
            &-light {
                background: var(--c-white);
                color: var(--c-black);
            }
            &-dark {
                background: var(--c-primary);
                color: var(--c-white);
            }
        }

        &--variant {
            &-alert {
                top: 24px;
                right: 24px;
                padding: 15px 51px 15px 21px;
                font-size: 16px;
                width: auto;
                border-radius: var(--bor-rad-base);
                pointer-events: all;

                .c-bkb-modal-view__close-btn-alert {
                    display: block;
                }
            }

            &-modal-form,
            &-modal {
                border-radius: 24px;
                border: 1px solid var(--c-dark-blue);
                width: 28.541%;
                min-width: 30.208%;
                padding: 3.125%;
                pointer-events: all;
            }
            &-pop-up,
            &-cookie {
                border-radius: 24px;
                border: 1px solid var(--c-dark-blue);
                width: 28.541%;
                min-width: 33.208%;
                padding: 3.125%;
                pointer-events: all;
            }
            &-modal-whitespace {
                border-radius: 24px;
                border: 1px solid var(--c-dark-blue);
                width: min-content;
                min-width: 25vw;
                // display: inline-block;
                padding: 3.125%;
                pointer-events: all;
                & > div {
                    width: 100%;
                    & > div {
                        gap: 1.25vw;
                    }
                }
                & h2 {
                    white-space: nowrap;
                    font-size: 1.88vw;
                }
            }

            &-video {
                border-radius: 0 !important;
                padding: 0 !important;
                border: none !important;
                min-height: 320px !important;
                max-height: 80% !important;
                min-width: 480px !important;
                max-width: 90% !important;
                height: 45.1vw !important;
                width: 81.04vw !important;

                & > div {
                    height: 100%;
                }

                .c-bkb-home-view__thumbnail-yt-player__iframe > div {
                    display: flex;
                }
            }
        }
    }

    &__close-btn {
        position: absolute;
        top: -72px;
        right: 0;
        background: none;
        border: none;
        display: flex;
        cursor: pointer;
        color: var(--c-white);
        align-items: center;
        font-family: var(--font-roboto);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        gap: 12px;

        &--icon {
            path {
                fill: var(--c-white) !important;
            }
        }

        &--dark {
            color: var(--c-dark-blue);

            path {
                fill: var(--c-dark-blue) !important;
            }
        }
    }
    @include customMaxResponsive(1590) {
        &__content {
            &--variant {
                &-video {
                    height: 40vw !important;
                    width: 80vw !important;
                }
            }
        }
    }
    @include devices(desktop) {
        overflow-y: scroll;
        &__content {
            &--variant {
                &-modal-form {
                    transform: translate(-50%, -50%);
                    padding: 2%;
                    gap: 10px;
                    width: 50% !important;
                    min-width: 200px;
                }

                &-modal {
                    width: 35%;
                }
                // &-modal{
                //     // width: 35%;
                // }
            }
        }
    }
    @include devices(tablet) {
        &__content {
            &--variant {
                &-modal-form {
                    transform: translate(-50%, -50%);
                    padding: 2.5%;
                    width: 70% !important;
                }
            }
        }
    }

    @include devices(mobile) {
        height: 100vh;
        overflow-y: scroll;
        max-height: calc(100% - env(safe-area-inset-bottom));
        &__content {
            &--center {
                top: 80px;
                transform: translate(-50%, 0);
            }
            &--variant {
                &-modal,
                &-modal-form {
                    padding: 20px 16px;
                    gap: 10px;
                    border-radius: 8px;
                    width: 90% !important;
                    min-width: 200px;
                }
                &-video {
                    min-width: 290px !important;
                    width: 87.44vw !important;
                    height: 115.94vw !important;
                }
                &-pop-up {
                    border: 1px solid var(--c-dark-blue);
                    padding: 20px 16px;
                    gap: 10px;
                    border-radius: 8px;
                    width: 90% !important;
                    min-width: 200px;
                    pointer-events: all;
                }
                &-modal-whitespace {
                    border: 1px solid var(--c-dark-blue);
                    width: 90%;
                    border-radius: 8px;
                    padding: 20px 16px;
                    pointer-events: all;
                    & > div {
                        width: 100%;
                        & > div {
                            gap: 20px;
                        }
                    }
                    & h2 {
                        white-space: break-spaces;
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
            }
        }
    }
}
