@font-face {
    font-family: 'Biocad Display';
    src: url('BiocadDisplay-Regular.woff2') format('woff2'),
        url('BiocadDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biocad Display';
    src: url('BiocadDisplay-Bold.woff2') format('woff2'),
        url('BiocadDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
