@import '../fonts/Roboto/stylesheet.css', '../fonts/BiocadDisplay/stylesheet.css', 'variables',
    'mixins';

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-roboto);
    font-variant-ligatures: none;
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: visible;
    overflow-wrap: break-word;
    color: var(--c-text-black);
}

input {
    border: none;
    outline: none;
}

a {
    outline: none;
    text-decoration: none;
    color: var(--c-blue-900);
}

.cursor-pointer {
    cursor: pointer;
}

.container {
}
