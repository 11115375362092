:root {
    // for responsive
    --sm: 37.5em;
    --md: 48em;
    --lg: 64em;
    --xl: 75em;

    // FONTS
    --font-roboto: 'Roboto', sans-serif;
    --font-biocad: 'Biocad Display', sans-serif;
    // FONTS

    // COLORS

    --c-grey: #e8e8ec;
    --c-grey-900: #202226;
    --c-grey-800: #37393e;
    --c-grey-700: #54575e;
    --c-grey-600: #737780;
    --c-grey-500: #9ca2ad;
    --c-grey-5: #aeb4c1;
    --c-grey-4: #cbcbcb;
    --c-grey-2: #f4f5f6;
    --c-grey-400: #b9bec9;
    --c-grey-300: #d3d7de;
    --c-grey-200: #e9ecf2;
    --c-grey-100: #f5f7fa;
    --c-light-grey: #fafafb;
    --c-grey-background: #f4f5f7;
    --c-green: #29c178;
    --c-cyan: #78e6d0;
    --c-light-green: #18d04b;
    --c-dark-blue: #0c204d;
    --c-blue-900: #091c45;
    --c-blue-800: #112552;
    --c-blue-700: #102759;
    --c-blue-600: #0c204d;
    --c-blue-500: #4e5c7b;
    --c-blue-400: #5e6c8a;
    --c-blue-300: #0067bb;
    --c-purple: #9d77ee;
    --c-purple-700: #9d77ee;
    --c-purple-500: var(--c-purple);
    --c-purple-300: #bfa3fd;
    --c-red-500: #ff5436;
    --c-red-300: #e16662;
    --c-yellow: #f2d43d;
    --c-orange: #f4ad46;
    --c-orange-700: #f69f21;
    --c-orange-500: var(--c-orange);

    --c-primary: var(--c-blue-600);
    --c-secondary: var(--c-blue-300);
    --c-white: #ffffff;
    --c-black: #060717;
    --c-text-black: var(--c-blue-600);
    // COLORS

    // TYPOGRAPHY
    // TYPOGRAPHY

    // BORDER-RADIUS
    --bor-rad-small: 4px;
    --bor-rad-base: 8px;
    --bor-rad-large: 12px;
    // BORDER-RADIUS

    // SPACES
    --space-base: 8px;

    --space-sm: 4px;
    --space-md: 16px;
    --space-lg: 24px;
    --space-xl: 48px;
    --space-mobile: 40px;

    --grid-width: 1512px;
    --grid-gap: var(--space-lg);
    // SPACES

    // TIMINGS
    --timing-short: 0.15s;
    --timing-base: 0.25s;
    --timing-long: 0.4s;
    // TIMINGS
}
