@mixin typo-h1 {
    margin: 0;
    font-family: var(--font-biocad);
    font-weight: 700;
    font-size: 60px;
    line-height: 1em;
}

@mixin typo-h-48 {
    margin: 0;
    font-family: var(--font-biocad);
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
}

@mixin typo-h2 {
    margin: 0;
    font-family: var(--font-biocad);
    font-weight: 700;
    font-size: 36px;
    line-height: 1em;

    @include devices(sMobile) {
        font-size: 32px;
    }
}

@mixin typo-h3 {
    margin: 0;
    font-family: var(--font-biocad);
    font-weight: 700;
    font-size: 24px;
    line-height: 1em;
}

@mixin typo-h4 {
    margin: 0;
    font-family: var(--font-roboto);
    font-size: 18px;
    line-height: 24px;
}

@mixin typo-h5 {
    margin: 0;
    font-family: var(--font-roboto);
    font-size: 14px;
    line-height: 18px;
}

@mixin typo-p {
    margin: 0;
    font-family: var(--font-roboto);
    font-size: 16px;
    line-height: 1.5em;
}

@mixin typo-p-sm {
    margin: 0;
    font-family: var(--font-roboto);
    font-size: 12px;
    line-height: 1.3em;
}

@mixin typo-btn {
    font-family: var(--font-roboto);
    font-weight: 400;
    font-size: 16px;
    line-height: 1em;
}

@mixin dark-block {
    background-color: var(--c-primary);
    color: var(--c-white);
}

@mixin display-f {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin button {
    @include typo-btn();

    position: relative;
    padding: 0 3em;
    border-radius: var(--bor-rad-base);
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--c-primary);
    border-width: 2px;
    border-style: solid;
    overflow: hidden;
    cursor: pointer;
    transition: border-color var(--timing-base) ease-in-out, color var(--timing-base) ease-in-out;

    &::before {
        content: '';
        position: absolute;
        display: block;
        background-color: var(--c-secondary);
        width: 105%;
        padding-bottom: 100%;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        pointer-events: none;
        transition: transform var(--timing-base) ease-in-out, opacity var(--timing-base) ease-in-out;
    }

    span {
        pointer-events: none;
        transform: translateZ(0);
        transition: transform var(--timing-short);
    }

    &:hover {
        //border-color: var(--c-secondary);
        color: var(--c-white);

        &::before {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    &:focus-visible {
        outline: 3px solid var(--c-secondary);
    }

    &:active {
        span {
            transform: scale(0.95);
        }
    }
}

@mixin customMinResponsive($width) {
    @media only screen and (min-width: $width + px) {
        @content;
    }
}

@mixin customMaxResponsive($width) {
    @media only screen and (max-width: $width + px) {
        @content;
    }
}

@mixin customMinHeight($height) {
    @media only screen and (min-height: $height + px) {
        @content;
    }
}

@mixin customMaxHeight($height) {
    @media only screen and (max-height: $height + px) {
        @content;
    }
}

@mixin devices($breakpoint) {
    @if $breakpoint == sMobile {
        @media only screen and (max-width: 450px) {
            @content;
        }
    }
    @if $breakpoint == mobile {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }
    @if $breakpoint == tablet {
        @media (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @if $breakpoint == desktop {
        @media (min-width: 1025px) and (max-width: 1440px) {
            @content;
        }
    }
    @if $breakpoint == lDesktop {
        @media only screen and (min-width: 1440px) {
            @content;
        }
    }
}

@mixin response($device) {
    @if $device == d {
        // response size for desktop - (screen>1680px)
        @media (max-width: 1680px) {
            @content;
        }
    } @else if $device == t {
        // response size for table - (screen>768px)
        @media (max-width: 768px) {
            @content;
        }
    } @else if $device == m {
        // response size for mobile - (screen>480px)
        @media (max-width: 480px) {
            @content;
        }
    } @else {
        // response size for large desktop - (screen<1681px)
        @media (min-width: 1681px) {
            @content;
        }
    }
}
